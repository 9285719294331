import React from 'react';
import { Container, Segment, Grid, Button } from 'semantic-ui-react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@plone/volto/components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';

import logoHZBSVG from '@package/icons/hzb-logo.svg';
import logoFZJSVG from '@package/icons/logo_fzj.svg';
import logoFAUSVG from '@package/icons/fau.svg';
import printSVG from '@package/icons/print.svg';
import xSVG from '@package/icons/x_hiern.svg';
import youtubeSVG from '@package/icons/youtube.svg';
import upSVG from '@plone/volto/icons/collapse-up.svg';
import linkedInSVG from '@package/icons/linkedin_hiern.svg';
import mastodonSVG from '@package/icons/mastodon_hiern.svg';

const HiERNFooter = () => {
  const content = useSelector((state) => state.content.data);
  const language = useSelector((state) => state.intl.locale);

  return (
    <footer id="footer">
      {content?.['@type'] !== 'Member' && (
        <div className="footer-tools">
          <Container>
            <Grid stackable columns="3">
              <Grid.Column className="print">
                <Button basic icon onClick={() => window.print()}>
                  <Icon name={printSVG} size="24px" />
                  <FormattedMessage id="print" defaultMessage="print" />
                </Button>
              </Grid.Column>
              <Grid.Column className="scroll-to-top">
                <AnchorLink offset="0" href="#main">
                  <Button basic icon>
                    <Icon name={upSVG} size="24px" />
                    <FormattedMessage
                      id="to the top"
                      defaultMessage="to the top"
                    />
                  </Button>
                </AnchorLink>
              </Grid.Column>
              <Grid.Column className={cx('social-media')}>
                <a
                  aria-label="hi-ern Youtube"
                  href="https://www.youtube.com/channel/UCK8WS6Tdda_DtrUrRTcLxBA"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name={youtubeSVG} size="24px" />
                </a>
                <a
                  aria-label="hi-ern X"
                  href="https://twitter.com/hiern_de"
                  target="_blank"
                  rel="noreferrer"
                  class="x-icon"
                >
                  <Icon name={xSVG} size="20px" />
                </a>
                <a
                  aria-label="hi-ern linkedin"
                  href="https://www.linkedin.com/company/hi-ern/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name={linkedInSVG} size="24px" />
                </a>
                <a
                  aria-label="hi-ern mastodon"
                  href="https://social.fz-juelich.de/@hi_ern"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name={mastodonSVG} size="24px" />
                </a>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
      )}
      <div className="ear-wrapper">
        <div className="ear"></div>
      </div>
      <div className="footer-bottom">
        <Segment vertical padded textAlign="center">
          <Container className="footer">
            <div className="upper">
              <div className="partof">
                <div className="type">
                  <span>
                    <FormattedMessage id="Part of" defaultMessage="Part of" />
                  </span>
                </div>
                <div>
                  <div className="icon-wrapper">
                    <Icon name={logoFZJSVG} size="74px" />
                  </div>
                </div>
              </div>
              <div className="cooperations">
                <div className="type">
                  <span>
                    <FormattedMessage
                      id="In cooperation with"
                      defaultMessage="In cooperation with"
                    />
                  </span>
                </div>
                <div className="logos">
                  <div className="icon-wrapper fau">
                    <Icon name={logoFAUSVG} size="105px" />
                  </div>
                  <div className="icon-wrapper hzb">
                    <Icon name={logoHZBSVG} size="48px" />
                  </div>
                </div>
              </div>
            </div>
            <div className="left">
              <ul className="links">
                <li className="item copyright">
                  © Helmholtz-Institut Erlangen-Nürnberg
                </li>
                <li className="item">
                  <a
                    aria-label="hi-ern webmail"
                    href={`${flattenToAppURL(
                      'https://webmail.fz-juelich.de/owa/auth/logon.aspx?replaceCurrent=1&url=https%3a%2f%2fwebmail.fz-juelich.de%2fowa%2f',
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="Webmail" defaultMessage="Webmail" />
                  </a>
                </li>
                <li className="item">
                  <a
                    aria-label="hi-ern wiki"
                    href="https://wiki.hi-ern.de/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="Wiki" defaultMessage="Wiki" />
                  </a>
                </li>
                <li className="item">
                  <Link to={`/${language}/impressum`}>
                    <FormattedMessage
                      id="Legal Notice"
                      defaultMessage="Legal Notice"
                    />
                  </Link>
                </li>
                <li className="item">
                  {language === 'de' ? (
                    <Link to="/de/datenschutzerklaerung">Datenschutz</Link>
                  ) : (
                    <Link to="/en/privacy-policy">Privacy Policy</Link>
                  )}
                </li>
              </ul>
            </div>
          </Container>
        </Segment>
      </div>
    </footer>
  );
};

export default HiERNFooter;
